<template>
  <div>
    <el-card>
      <div slot="header">万里牛ERP</div>
      <div style="font-size: 14px;margin-left:30px;line-height:25px">
        <div>订单推送规则：</div>
        <div>1.系统每10分钟主动推送订单至万里牛ERP</div>
        <div>2.仅推送待发货的快递发货单</div>
        <div>3.如果订单里有退款中或退款成功商品，推送至万里牛后，万里牛系统-线上留言框中将显示退款信息</div>
        <div>4.若急需发货，或订单发货前产生退款，或订单修改收货地址等，可点击下方[立即推送订单]按钮，更新订单在万里牛中的数据</div>
      </div>
      <div class="button" @click="push" :loading="loading">立即推送订单</div>
    </el-card>
  </div>
</template>

<script>
import {
  wanliniuorderpush
} from "@/api/TurnTomySelf.js"
export default {
  data () {
    return {
      loading:false,
    }
  },
  methods: {
    async push(){
      try{
        this.loading = true
        let res = await wanliniuorderpush()
        if(res.IsSuccess){
          this.$message.success('操作成功')
        }
      }finally{
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.button{
  width: 200px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #F5F5F5;
  cursor: pointer;
  margin:30px 0px 0px 30px 
}
</style>